import { Button, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { Book, Search } from 'react-feather';
import { HidableBranding as Branding } from '../../components/Branding';
import { ProductHuntBanner } from '../../components/ProductHuntBanner';
import { SEO } from '../../components/SEO';
import { Subheading } from '../../components/Subheading';
import { Text } from '../../components/Text';
import Layout, { Wrapper } from '../../layouts/Layout';
import { useCurrentUser } from '../../services/currentUser';
import styled from '../../styled';
import { COLORS } from '../../themes/colors';
import { InfoMessage } from '../../components/Alerts';

type IGuide = {
  bgColor: string;
  iconColor: string;
  title: string;
  description: string;
  icon: React.ReactNode;
  href: string;
  pro: boolean;
  comingSoon: boolean;
  topics: ITopic[];
};

type ITopic = 'Blogging' | 'SEO' | 'All';

const TOPICS: ITopic[] = ['All', 'Blogging', 'SEO'];

const GUIDES: IGuide[] = [
  {
    bgColor: COLORS.primary.dark,
    iconColor: COLORS.primary.light,
    href: '/guides/basics-of-blogging/',
    title: 'Blogging for Devs: Original Course (Extended)',
    description:
      "This handy, web-version of the Blogging for Devs Email Course lets you quickly refer to the various sections and tips anytime you're writing a new post.",
    icon: <Book size={56} />,
    comingSoon: false,
    pro: true,
    topics: ['Blogging', 'SEO']
  },
  {
    bgColor: '#9567f5',
    iconColor: '#d6bdff',
    href: '/seo-for-developers/',
    title: 'Technical SEO for Developers',
    description:
      'Learn how to audit and optimize your website for technical and content SEO using a modern tech stack.',
    icon: <Search size={56} />,
    comingSoon: true,
    pro: false,
    topics: ['SEO']
  }
];

const OuterGrid = styled('div')`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  max-width: 1048px;
  padding: 24px;
  margin: 0 auto;
`;

const GuideRowNoClick = styled('div')`
  display: flex;
  margin: 0 auto 36px;

  &:hover {
    background-color: ${(p) => p.theme.palette.grey['700']};
    border-radius: 8px;
  }
`;

const GuideRow = styled(Link)`
  display: flex;
  margin: 0 auto 36px;

  &:hover {
    background-color: ${(p) => p.theme.palette.grey['700']};
    border-radius: 8px;
  }
`;

const GuideTag = styled('div')`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  margin-right: ${(p) => p.theme.spacing(1)}px;
`;

const GuideProTag = styled(GuideTag)`
  background-color: ${(p) => p.theme.palette.primary.main};
`;

const GuideComingSoonTag = styled(GuideTag)`
  background-color: ${(p) => p.theme.palette.grey[900]};
`;

const GuidePreview = styled<'div', { bgColor: string; color: string }>('div')`
  width: 140px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.bgColor};
  color: ${(p) => p.color};
  border-radius: 8px;
  margin-right: ${(p) => p.theme.spacing(4)}px;

  svg {
    transform: rotate(-15deg);
  }
`;

const GuideText = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 162px);
  padding: ${(p) => p.theme.spacing(2)}px ${(p) => p.theme.spacing(2)}px 0 0;
`;

const GuideDescription = styled('p')`
  color: ${(p) => p.theme.palette.grey['300']};
  font-size: ${(p) => p.theme.typography.body1.fontSize};
  line-height: ${(p) => p.theme.typography.body1.lineHeight};
`;

const Guide = ({
  bgColor,
  iconColor,
  title,
  description,
  icon,
  href,
  comingSoon,
  pro
}: IGuide) => {
  if (comingSoon) {
    return (
      <GuideRowNoClick>
        <GuidePreview color={iconColor} bgColor={bgColor}>
          {icon}
        </GuidePreview>
        <GuideText>
          <div>
            <Typography variant="h5" component="h3" paragraph>
              <strong>{title}</strong>
            </Typography>
            <GuideDescription>{description}</GuideDescription>
            {pro && <GuideProTag>PRO</GuideProTag>}
            {comingSoon && <GuideComingSoonTag>Coming Soon</GuideComingSoonTag>}
          </div>
        </GuideText>
      </GuideRowNoClick>
    );
  }

  return (
    <GuideRow to={href}>
      <GuidePreview color={iconColor} bgColor={bgColor}>
        {icon}
      </GuidePreview>
      <GuideText>
        <div>
          <Typography variant="h5" component="h3" paragraph>
            <strong>{title}</strong>
          </Typography>
          <GuideDescription>{description}</GuideDescription>
          {pro && <GuideProTag>PRO</GuideProTag>}
          {comingSoon && <GuideComingSoonTag>Coming Soon</GuideComingSoonTag>}
        </div>
      </GuideText>
    </GuideRow>
  );
};

const IndexPage = () => {
  const { isAuthenticated, loadingUser } = useCurrentUser();
  const [topic, setTopic] = useState<ITopic>('All');

  return (
    <Layout>
      <ProductHuntBanner />
      <SEO
        title="Blogging for Devs Guides"
        siteUrl="https://bloggingfordevs.com"
        description="Get advice and resources for creating a strategy for your developer blog, and create content that reaches thousands without an existing audience."
        pathname="guides/"
      />
      <Wrapper>
        <div
          style={{
            margin: '0 auto',
            textAlign: 'center'
          }}
        >
          <Branding to="/">Blogging for Devs</Branding>
          <Typography variant="h4" component="h1" gutterBottom>
            Guides
          </Typography>
          <Text>
            Practical guides to help accelerate your blogging journey.
          </Text>
        </div>
      </Wrapper>
      <OuterGrid>
        <div>
          <Subheading paragraph>All Guides</Subheading>
          {GUIDES.filter((guide) =>
            topic !== null && topic !== 'All'
              ? guide.topics.includes(topic)
              : true
          ).map((guide) => (
            <Guide key={guide.title} {...guide} />
          ))}
        </div>
        <div>
          <InfoMessage>
            <strong>Head's up!</strong> We're just getting started here. Keep an
            eye out for new and updated material in the coming weeks.
          </InfoMessage>
          <br />
          <br />
          <Subheading paragraph>Topics</Subheading>
          {TOPICS.map((t) => (
            <Button
              variant={topic === t ? 'contained' : 'outlined'}
              color="primary"
              style={{ margin: '0 8px 12px 0' }}
              onClick={() => setTopic(t)}
            >
              {t}
            </Button>
          ))}
        </div>
      </OuterGrid>
    </Layout>
  );
};

export default IndexPage;
